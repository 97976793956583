import React, { useEffect, useState } from 'react';
import './App.css'; // Make sure to import your CSS file
import { BrowserRouter, Routes } from "react-router-dom";
import AnimatedRoutes from './components/AnimatedRoutes';

function App() {
  

  return (
    <BrowserRouter>
      <div className='app flex justify-center items-center min-h-screen'>
        <div className='w-4/5'>
          <AnimatedRoutes />
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App;
import {motion} from 'framer-motion';
import { flyingAnimation } from '../../animation.config';

const PortfolioPage = () => {
    return (
        <motion.div className='flex flex-col justify-center items-center' 
            initial={flyingAnimation.initial}
            animate={flyingAnimation.animate}
            exit={flyingAnimation.exit}
            transition={flyingAnimation.transition}
        >
            <p className="text-4xl font-bold text-center">
                Portfolio
            </p>
        </motion.div>
    )
}

export default PortfolioPage;
type Transition = {
    duration?: number;
    ease?: "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate" | [number, number, number, number];
    delay?: number;
    repeat?: number;
    repeatType?: "loop" | "reverse";
    repeatDelay?: number;
    type?: "tween" | "spring" | "inertia" | "just";
    bounce?: number;
    velocity?: number;
    damping?: number;
    mass?: number;
    stiffness?: number;
    restSpeed?: number;
    restDelta?: number;
};

type Variant = {
    [key: string]: number | string | [number, number] | [string, string] | Variant;
};

interface Animation {
    initial?: Variant;
    animate?: Variant;
    exit?: Variant;
    transition?: Transition;
}

export const flyingAnimation: Animation = {
    initial: { opacity: 0, y: 500 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -500 },
    transition: { duration: 0.8, delay: 0.2, ease: "easeInOut" }
};